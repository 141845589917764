import React, { useEffect } from 'react';
import './term_privacy.css';

function Terms() {

    // useEffect(()=>{
    //     window.scrollTo(0, 0);
    // })

    return (
        <div className="terms">
            <div className="terms__bgbox1 "/>
             <div className="terms__bgbox2 "/>
            <div className="terms_wrapper">
                <div className="terms_title">
                    <h2>Terms of Service</h2>
                </div>
                <div className="terms_content">
                  <h4 className="terms_content_boldText">Overview</h4> 
                  <p className="terms_content_p">This Terms of Use Agreement (the "Agreement") sets forth the terms and conditions which apply to your use of the &nbsp;
                       <span className="terms_content_span">ReChat</span> website.
                   By completing the sign up process you indicated that you agree to be bound by all of the terms in this Agreement.</p>

                   <h4 className="terms_content_boldText">Eligibility</h4> 
                   <p className="terms_content_p">You must be 18 years of age or older to visit or use this website in any manner. By visiting ReChat or accepting these Terms of Use,
                    you represent and warrant to ReChat that you are 18 years of age or older, and that you have the right, authority and capacity to agree to and abide by these Terms of Use. 
                    You also represent and warrant to ReChat that you will use this website in a manner consistent with any and all applicable laws and regulations.</p>

                    <h4 className="terms_content_boldText">Privacy of Information</h4> 
                    <p className="terms_content_p">"Personal Information" means all information about an identifiable individual.</p>
                    <p className="terms_content_p">ReChat collects personal information from you in order to create your online profile. ReChat will not use or disclose your personal
                     information for purposes other than those described in this policy.</p>
                    <p className="terms_content_p">If you have any questions with respect to our policies concerning the handling of your personal in-formation, or if you wish to request 
                    access to, or correction of, your personal information under our care and control, please contact us at: XXX.</p>

                    <h4 className="terms_content_boldText">By using ReChat you acknowledge that:</h4>
                    <ul>
                        <li className="terms_content_li">We cannot ensure the security or privacy of information you provide through the Internet and your email messages, and you release us 
                         from any and all liability in connection with the use of such information by other parties. </li>

                         <li className="terms_content_li">We cannot control and we are not responsible for the use by others of any information which you provide to them and you should
                              use caution in selecting the personal infor-mation you provide to others through ReChat. </li>

                         <li className="terms_content_li">We cannot assume any responsibility for the content of messages sent by other users of ReChat, and you release us from any and
                              all liability in connection with the contents of any communications you may receive from other users.</li>

                         <li className="terms_content_li">We cannot assume any responsibility for the accuracy or content of the profile of any user of ReChat.</li>    

                         <li className="terms_content_li">We cannot guarantee nor assume any responsibility for verifying the accuracy of the in-formation provided by other users of ReChat.</li> 
                    </ul> 

                    <h4 className="terms_content_boldText">User Agreement</h4>
                    <p className="terms_content_p">This Agreement constitutes your agreement with ReChat with respect to your use of ReChat.
                      You must agree to abide by all of the terms and conditions contained in this Agreement in order to be-come or remain a user of ReChat.</p>

                      <h4 className="terms_content_boldText">Right to Use</h4>
                     <p className="terms_content_p">Your right to use ReChat is subject to any limitations, conditions and restrictions established by us from time to time, 
                     in our sole discretion. We may alter, suspend or discontinue any aspect of ReChat at any time, including the availability of any ReChat feature, database or content.
                      We may also im-pose limits on certain features and aspects of ReChat or restrict your access to parts or all of ReChat without notice or liability. </p>

                     <h4 className="terms_content_boldText">Unlawful Use is Prohibited</h4>
                     <p className="terms_content_p">You may not use ReChat for any unlawful purpose. We may refuse to grant you a username
                      that im-personates someone else, is protected by trade-mark or proprietary law, or is vulgar or otherwise offensive, as determined by us in our sole discretion.</p>

                     <h4 className="terms_content_boldText">Code of Conduct</h4>
                     <p className="terms_content_p">You agree to use ReChat in accordance with the following Code of Conduct:</p>
                     <ul>
                     <li className="terms_content_li">You will keep all information provided to you through ReChat by another user as private and confidential
                      and will not give such information to anyone without the permission of the person who provided it to you.</li>
                     <li className="terms_content_li">You will not use ReChat to engage in any form of harassment or offensive behaviour, including but not limited 
                                to the posting of private communications, pictures or record-ings which contain libellous, slanderous, abusive or defamatory 
                               statements, or racist, pornographic, obscene, or offensive language. </li>
                     <li className="terms_content_li">You will not use ReChat to infringe the privacy rights, property rights, or any other rights of any person.</li>
                     <li className="terms_content_li">You will not submit any kind of material or information that is fraudulent or otherwise un-lawful or violates any law.</li>
                     <li className="terms_content_li">You will not use ReChat to distribute, promote or otherwise publish any material contain-ing any solicitation
                                           for funds, advertising or solicitation for goods or services. </li>
                     <li className="terms_content_li">You will not use ReChat to distribute, promote or otherwise publish any kind of mali-cious code or do anything else 
                                       that might cause harm to ReChat or to other members' systems in any way.</li>
                     <li className="terms_content_li">You will not post or transmit in any manner any contact information including, but not limited to, email addresses, "instant messenger" nicknames, 
                               telephone numbers, postal addresses, URLs, or full names through your publicly posted information. </li>
                     </ul>
                     <p className="terms_content_p">While we may decide to permanently close any user account found to have violated the above Code of Conduct,
                                                   we cannot guarantee our enforcement activity will be either complete or consistent.</p>

                     <h4 className="terms_content_boldText">Usage Restrictions</h4>
                     <p className="terms_content_p">The following restrictions apply to the use of ReChat:</p>
                     <ul>
                     <li className="terms_content_li">You shall not create an Account or access ReChat if you are under the age of 18.</li> 
                     <li className="terms_content_li">You shall not create an Account using a false identity or information, or on behalf of someone other than yourself.</li> 
                     <li className="terms_content_li">You shall not create an Account or use ReChat if you are a convicted sex offender.</li> 
                     <li className="terms_content_li">You shall not have an Account or use ReChat if you have been previously removed by ReChat staff or moderators.</li> 
                     <li className="terms_content_li">You shall not use your Account for commercial purposes.</li> 
                     <li className="terms_content_li">You shall not use your Account to advertise, solicit, or transmit any commercial adver-tisements, including chain letters, junk e-mail or repetitive messages to anyone.</li> 
                     <li className="terms_content_li">You shall not use your Account to engage in any illegal conduct.</li> 
                     <li className="terms_content_li">You shall not sublicense, rent, lease, sell, trade, gift, bequeath or otherwise transfer your Account or any Virtual Items (Badges, Credits, etc)
                                       associated with your Account to a third party without ReChat’s explicit permission.</li> 
                     <li className="terms_content_li">You shall not access or use an Account or Virtual Items that have been sublicensed, rented, leased, sold, traded, gifted, bequeathed, or otherwise transferred from the origi-nal Account creator.</li> 
                     <li className="terms_content_li">You shall not allow anyone other than the original Account creator gain access to ReChat using the Account.</li> 
                     <li className="terms_content_li">You shall accept full responsibility for any unauthorized use of ReChat by third parties in connection with your Account.</li>
                     </ul> 

                     <h4 className="terms_content_boldText">Personal Liability</h4>
                     <p className="terms_content_p">You will be solely responsible for anything and everything you submit to ReChat. We cannot assume any responsibility or 
                     liability over any material or content published by you or other users on ReChat. The responsibility over any published material or content lies solely on the user that submitted it to ReChat.</p>

                     <h4 className="terms_content_boldText">Monitoring of Information</h4>
                     <p className="terms_content_p">We reserve the right to monitor all data submitted by you to ReChat, including private communica-tions, to ensure that it conforms to our content guidelines,code of conduct and usage restrictions.</p>

                     <h4 className="terms_content_boldText">Removal of Information</h4>
                     <p className="terms_content_p">While we do not and cannot review every message or other material posted or sent by users of ReChat, and are not responsible for any content of these messages or materials, we reserve the
                      right, but are not obligated, to delete, move, or edit messages or materials, including without limita-tion profiles, public postings and messages, that we, in our sole discretion, deem to violate the Code of Conduct 
                      set out above or any other applicable content guidelines, or to be otherwise unac-ceptable. You shall remain solely responsible for the content of profiles, public postings, messages and other materials you may upload to ReChat or users of ReChat. </p>

                     <h4 className="terms_content_boldText">Termination of Access to ReChat</h4>
                     <p className="terms_content_p">We may, in our sole discretion, terminate or suspend your access to all or part of ReChat at any time, with or without notice, for any reason, including, without limitation, breach of this Agreement.
                      Without limiting the generality of the foregoing, any fraudulent, abusive, or otherwise illegal activity, or that may otherwise affect the enjoyment of ReChat or the Internet by others may be grounds for termination of your 
                                      access to all or part of ReChat at our sole discretion, and you may be referred to appropriate law enforcement agencies.</p>

                     <h4 className="terms_content_boldText">Proprietary Information</h4>
                     <p className="terms_content_p">ReChat contains information which is proprietary to us, our partners, and our users. We assert full copyright protection in ReChat. Information posted by us, our partners or users of ReChat may be protected
                      whether or not it is identified as proprietary to us or to them. You agree not to modify, copy or distribute any such information in any manner whatsoever without having first received the express written permission of the owner of such information.</p>

                     <h4 className="terms_content_boldText">Suspension of ReChat</h4>
                     <p className="terms_content_p">You acknowledge that we are not responsible for suspension of ReChat, regardless of the cause of the interruption or suspension.</p>

                     <h4 className="terms_content_boldText">Ownership of User Contributed Content,Virtual Items and Credits</h4>
                     <p className="terms_content_p">ReChat owns, has licensed, or otherwise has rights to use all of the content that appears in ReChat. Notwithstanding
                      any provision to the contrary herein, you agree that you have no right or title in or to any content that appears in ReChat, including without
                       limitation the Virtual Items (Badges, Credits, etc), or any other attributes associated with an Account or stored on ReChat.</p>

                     <h4 className="terms_content_boldText">Account Security</h4>
                     <p className="terms_content_p">Your account is private and should not be used by anyone other than you. You are responsible for all usage
                       or activity on ReChat by users using your password, including but not limited to use of your password by any third-party.</p>

                     <h4 className="terms_content_boldText">DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h4>
                     <p className="terms_content_p">YOU UNDERSTAND AND AGREE THAT YOU USE RECHAT AND ITS RELATED SERVICES AT YOUR OWN RISK. RECHAT’S SERVICES ARE PROVIDED
                      ON AN "AS IS" BASIS WITHOUT REP-RESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF TITLE,
                       NON-INFRINGEMENT, OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OTHER THAN THOSE WAR-RANTIES WHICH ARE INCAPABLE OF EXCLUSION UNDER LAW. </p>

                     <p className="terms_content_p">YOU ACKNOWLEDGE THAT USE OF THE SERVICE IS AT YOUR OWN RISK. WE DO NOT REPRE-SENT OR ENDORSE THE ACCURACY OR RELIABILITY
                      OF ANY MEMBER PROFILE, ADVICE, OPINION, STATEMENT OR OTHER INFORMATION DISPLAYED, UPLOADED OR DISTRIBUTED THROUGH THE SERVICE BY RECHAT, OUR PARTNERS OR ANY USER
                       OF THE SERVICE OR ANY OTHER PERSON OR ENTITY. YOU ACKNOWLEDGE THAT ANY RELIANCE UPON ANY SUCH OPINION, MEMBER PROFILE, ADVICE, STATEMENT OR INFORMATION SHALL BE AT YOUR SOLE RISK.
                        YOUR CONTINUED USE OF THE SERVICE NOW, OR FOLLOWING THE POSTING OF NOTICE OF ANY CHANGES IN THIS AGREEMENT, WILL CONSTITUTE A BINDING ACCEPTANCE BY YOU OF THIS AGREEMENT, OR ANY SUBSEQUENT MODIFICATIONS.</p>

                     <p className="terms_content_p">YOU HEREBY ACKNOWLEDGE AND AGREE THAT UNDER NO CIRCUMSTANCES WILL RECHAT, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS AND THIRD PARTY CONTENT PROVIDERS OR
                      LICENSORS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOSS WHATSOEVER CAUSED BY YOUR USE OR RELIANCE ON INFORMATION OBTAINED THROUGH THE CONTENT DISTRIBUTED BY RECHAT AS WELL AS ANY DIRECT,
                       INDIRECT, INCIDENTAL, SPECIAL, PUNI-TIVE OR CONSEQUENTIAL DAMAGES OR INJURY ARISING OUT OF THE USE OR INABILITY TO USE THE RECHAT SERVICE OR OUT OF THE BREACH OF ANY WARRANTY, OR CAUSED BY
                        ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT OR DESTRUCTION OR UNAUTHORIZED
                         ACCESS TO AND/OR ALTERATION OF THE <span className="terms_content_span">RECHAT</span> WEBSITE, NO MATTER WHETHER SUCH CLAIMS ARE BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR ANY OTHER CAUSE OF ACTION, AND REGARDLESS
                          OF WHETHER RECHAT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. </p>

                     <p className="terms_content_p">SOME JURISDICTIONS LIMIT THE AVAILABILITY OF SUCH LIMITATION OF LIABILITY, IN WHICH CASE THE PROVISIONS OF THIS SECTION MAY NOT APPLY TO YOU. NOTWITHSTANDING 
                                   THE FOREGOING, IN NO EVENT SHALL OUR LIABILITY EXCEED THE SUM OF $10.00 US DOLLARS.</p>
                     

                     <h4 className="terms_content_boldText">Indemnity</h4>
                     <p className="terms_content_p">You hereby agree to indemnify, defend and hold harmless ReChat and all officers, directors, owners, agents, information providers, affiliates, licensors and licensees (collectively, the "Indemnified Par-ties")
                      from and against any and all liability and costs, including, without limitation, reasonable attor-neys' fees, incurred by the Indemnified Parties in connection with any claim arising out of any breach by you of this Agreement or the foregoing 
                      representations, warranties and covenants. You shall co-operate as fully as reasonably required in the defense of any such claim. ReChat reserves the right, at its own expense, to assume the exclusive defense and control of any matter subject to indemnifi-cation by you. </p>

                     <h4 className="terms_content_boldText">Changes to the Agreement</h4>
                     <p className="terms_content_p">We reserve the right, at any time, to change or modify the terms and conditions applicable to your usage of ReChat. Such changes will become effective upon its publication on the ReChat website. Your continued use of our service after
                      being notified of such changes shall be deemed to constitute acceptance by you of any such changes, modifications, additions or deletions. </p>
                      <p className="terms_content_p">You agree to review this Agreement periodically to be aware of any such revisions.</p>

                     <h4 className="terms_content_boldText">Miscellaneous</h4>
                     <p className="terms_content_p">This Agreement represents the entire agreement between you and ReChat regarding the use of our services and supersedes any other agreement or understanding on the subject matter. This Agree-ment, your rights and obligations, and all actions 
                                         contemplated by this Agreement shall be governed by the laws of the United States. As a condition of using ReChat's services, each user agrees that any and all disputes and causes of action arising out of or connected with ReChat, shall be resolved 
                                         through arbitration, with such arbitration to be held in the United States.</p>

                     <p className="terms_content_p">Additionally, except where prohibited by law, as a condition of using ReChat services, you agree that any and all disputes and causes of action arising out of or connected to our services shall be re-solved individually, without resort to any
                                        form of class action. You also agree that regardless of any statute or law to the contrary, any claim or cause of action arising from or related to the use of ReChat must be filed within one (1) year after such claim or cause of action arose or be forever barred.
                                        The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder. </p>

                     <p className="terms_content_p">If any provision of this Agreement is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that this Agreement shall otherwise remain in full force and effect and be enforceable.
                       This Agreement is not assignable, transferable or subli-censable without the prior written consent of ReChat. ReChat may assign this Agreement in whole or in part. No agency, partnership, joint venture, or employment is created as a result of this Agreement. Headings are 
                       for convenience only and have no legal or contractual effect. All notices under this Agreement shall be in writing and shall be deemed to have been duly given when receipt is electroni-cally confirmed, if transmitted by facsimile or email or upon receipt, if sent by certified or registered mail, return receipt requested. </p>

                     <h4 className="terms_content_boldText">Disclosure and Other Communication</h4>
                     <p className="terms_content_p">We reserve the right to send electronic mail to you, for the purpose of informing you of changes or additions to ReChat, or of any ReChat related products and services. We reserve the right to dis-close information about your usage of ReChat and 
                                                  demographics in forms that do not reveal your personal identity.</p>

                </div>
            </div>
            
        </div>
    )
}

export default Terms;
