import React from "react";
import TwitterIcon from "../../assets/images/Twitter.png";
import FacebookIcon from "../../assets/images/Facebook.png";
import { Link } from "react-router-dom";
import "./footer.css";

function Footer() {
  return (
    <div className="Homepage__footer">
      <div className="Homepage__footer_bgbox" />
      <div className="Homepage__footer_1">
        <div className="Homepage__footer_2_bigText">ReChat</div>
        <div className="Homepage__footer_2_smallText">
          An integrated and interactive chat tool that allows you to administer,
          monitor, and analyze live conversations.
        </div>

        <div className="Homepage__footer_1_socials">
          <a
            href="https://www.facebook.com/Rechat-103308698466469"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={FacebookIcon}
              className="Homepage__social_icon"
              alt="facebook"
            />
          </a>
          <a
            href="https://twitter.com/ReChatWeb"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={TwitterIcon}
              className="Homepage__social_icon"
              alt="twitter"
            />
          </a>
        </div>
      </div>

      <div className="Homepage__footer_2">
        <div className="Homepage__footer_2_bigText">Navigation</div>
        <div className="Homepage__navigation_items">
          <Link to="/" className="footer_navLinks">
            Home
          </Link>
          <Link to="/about" className="footer_navLinks">
            About Us
          </Link>
          <Link to="/features" className="footer_navLinks">
            Features
          </Link>
          <Link to="/help" className="footer_navLinks">
            Help
          </Link>
          <Link to="/terms" className="footer_navLinks">
            Terms & Privacy
          </Link>
          <Link to="/contact" className="footer_navLinks">
            Contact Us
          </Link>
        </div>
      </div>

      <div className="Homepage__footer_3">
        <div className="Homepage__footer_2_bigText">Contact Us</div>
        <div className="Homepage__footer_2_smallText">
          rechat.chat@gmail.com
        </div>
      </div>
    </div>
  );
}

export default Footer;
