import "./Help.css";
import React from "react";

function Help() {
  return (
    <div className="Help">
      <div className="Help_1">
        <div className="Help__whoTitle">Using ReChat with Qualtrics</div>
        <div className="Help__card_whoDescription">
          This document provides a basic guide to integrate a ReChat interface
          into a Qualtrics survey and work with the resulting data.
        </div>

        <div className="Help__What_Middle_Title">3 easy steps to integrate</div>
      </div>

      <div className="Help_2">
        <div className="Homepage__steps Help_steps">
          <div className="Homepage__card">
            <div className="d-flex">
              <div className="Homepage__number mobile__circle mt-5">1</div>
              <div className="Help_Homepage__card_title">
                Create a ReChat Room Template
              </div>
            </div>
            <div className="Help_2_card1_description">
              <ol>
                <li>Log in to your ReChat account</li>
                <li>
                  From the Home page, click the button “Manage Room Templates”
                </li>
                <li>
                  Click the button “Create Room Template” in the upper right
                  hand corner
                </li>
                <li>
                  Room Template Configuration:
                  <ul>
                    <li>
                      Room Template Name: This is the internal
                      (researcher-facing) name for the template you are
                      creating. Participants will not see this. Enter a name
                      that distinguishes this template from your other templates
                      - this is useful for keeping track of different studies,
                      or different templates used within the same study (e.g. to
                      apply different treatments).
                    </li>
                    <li>
                      Public Name: This will be visible to participants, so be
                      sure to enter a name that does not reveal the study's
                      intent or other sensitive information. A generic name like
                      “Chat” is always an option, if a more descriptive name is
                      not desired.
                    </li>
                    <li>
                      Description: This is an internal (researcher-facing)
                      description that can be used to keep track of how you
                      intend to use the template. It is not visible to
                      participants.
                    </li>
                    <li>
                      Language: Select the language for the chat interface.
                      Currently English and Chinese are supported.
                    </li>
                    <li>
                      Chat End Mode: Chats can be set up to end after a fixed
                      duration (“By Time”) or after all Chat Questions have been
                      posed (“By Questions”). For the purposes of this Qualtrics
                      integration tutorial, select “By Time”.
                    </li>
                    <li>
                      Expiration Time: For chats that end “By Time,” this is the
                      duration of each chat. Enter a duration in minutes. Note
                      that the attached{" "}
                      <a
                        href="https://rechat-public-assets.s3.us-east-2.amazonaws.com/ReChat_Template.qsf"
                        target="_blank"
                      >
                        QSF survey file
                      </a>{" "}
                      refers to a 10-minute chat, but this can easily be edited.
                    </li>
                    <li>
                      Waiting Time: This is the maximum amount of time (in
                      minutes) that a participant will be held in the waiting
                      room. If no chat partner(s) arrive within this time
                      window, the participant will not participate in a chat. We
                      generally consider 5 minutes to be a reasonable waiting
                      time. Longer times will reduce attrition, however
                      participants should be compensated for their waiting time.
                    </li>
                    <li>
                      Number of maximum participants: This is the maximum number
                      of participants per chat. Once this number of participants
                      is present in the waiting room, they will enter a chatroom
                      and their chat will begin (and subsequent participants
                      will enter a fresh waiting room). If the “Waiting Time”
                      expires before the maximum number is reached and there are
                      at least two participants present, the participants who
                      are present will be placed into a chat. For ease of
                      demonstration in this tutorial, we recommend selecting 2.
                    </li>
                    <li>
                      End Instruction: This is the instruction that participants
                      will receive when their chat ends. For the purpose of
                      embedding a chat in a Qualtrics survey, it is important
                      that this message draw participants' attention to their
                      completion code (see following point). In our studies, we
                      have used the following End Instruction: “This chat is
                      complete! Please copy this completion code into the text
                      box near the bottom of the survey page. When you have done
                      this, a green 'Continue' button will appear, allowing you
                      to continue and finish the study.”
                    </li>
                    <li>
                      Prefix for completion code: After a chat, ReChat generates
                      a completion code. When ReChat is integrated into a
                      survey, it is important to instruct participants to copy
                      their code into a text field in the survey. This is
                      necessary for linking chat data to survey data for each
                      participant, and for handling different survey logic in
                      the case of waiting room time-outs. To serve the latter
                      purpose, completion codes are pre-pended with distinct
                      codes signifying successful chat completion as opposed to
                      waiting-room time-out. The attached{" "}
                      <a
                        href="https://rechat-public-assets.s3.us-east-2.amazonaws.com/ReChat_Template.qsf"
                        target="_blank"
                      >
                        QSF survey file
                      </a>{" "}
                      assumes that successful chat completion codes are
                      prepended with “2CC,” and although this can be customized,
                      there will not generally be a need to do so.
                    </li>
                    <li>
                      Waiting Room Timeout Message: This is the instruction that
                      participants will receive if they time out in the waiting
                      room. In our studies, we used the following message:
                      “Nobody turned up to chat with you, but don't worry, you
                      will still be compensated for your time. Please copy this
                      completion code into the text box near the bottom of the
                      survey page. When you have done this, a green 'Continue'
                      button will appear, allowing you to continue and finish
                      the study.”
                    </li>
                    <li>
                      Prefix for timeout completion code: This prefix should be
                      different from the prefix for completion codes generated
                      from successful chats. The attached{" "}
                      <a
                        href="https://rechat-public-assets.s3.us-east-2.amazonaws.com/ReChat_Template.qsf"
                        target="_blank"
                      >
                        QSF survey file
                      </a>{" "}
                      assumes that waiting room timeout completion codes are
                      prepended with “2WT,” and although this too can be
                      customized, there will not generally be a need to do so.
                    </li>
                    <li>
                      Chat Options and Upload Chatbot Transcript: These are
                      advanced options for chats that do not affect Qualtrics
                      integration. For the purposes of this tutorial, they can
                      be ignored.
                    </li>
                    <li>
                      Waiting Room Information: Enter some information that
                      participants will see while they are waiting in the
                      waiting room. For example, we used the following text for
                      the “Welcome Message”: “Welcome! Your chat will begin when
                      your partner arrives. If no partner arrives within 5
                      minutes, your chat will be canceled (you will still
                      receive a $1 bonus to thank you for your time).” Be sure
                      to adjust this to match your actual waiting time and
                      compensation amount. The Welcome Image is optional, and
                      can be ignored for the purpose of this tutorial.
                    </li>
                    <li> Click “Submit” at the bottom of the page.</li>
                    <li> Click “OK”</li>
                    <li>
                      Placement Question: This is an advanced option that can be
                      ignored for the purpose of this tutorial.
                    </li>
                    <li>
                      Click “Chat Questions” in the toolbar. Chat questions
                      determine the topic of the chat, and are delivered as
                      messages from a “Moderator Bot”. In general, questions
                      will be created by the researcher prior to creating a chat
                      template that uses those questions (since questions will
                      often be shared across chat templates), however for the
                      purposes of this tutorial one may use pre-existing
                      questions. Questions are organized by Subject and
                      Language, so for the purposes of this tutorial, enter
                      “partisanship” as the subject, and “English” as the
                      language, and click “Fetch Questions”. Click the + symbol
                      beside a question to add it to the chat template, and
                      click “Submit.”
                    </li>
                    <li>
                      You can now exit the “Create Room Template” interface.
                      Note that “Video” and “Game” are advanced options that can
                      be ignored for the purposes of this tutorial.
                    </li>
                  </ul>
                </li>
                <li>
                  In the “Room Template List,” locate the template you just
                  created, and click “Copy Room Template URL”. Hold on to this
                  URL for Part 2. Note: there is also a button “Copy iFrame URL”
                  - although the method used to embed ReChat into Qualtrics in
                  Part 2 does employ an iFrame, this is already set up in the
                  attached{" "}
                  <a
                    href="https://rechat-public-assets.s3.us-east-2.amazonaws.com/ReChat_Template.qsf"
                    target="_blank"
                  >
                    QSF survey file
                  </a>
                  . To make things simpler for the user, we will simply set the
                  Room Template URL as an embedded data variable in Qualtrics,
                  so please copy the Room Template URL, and not the iFrame.
                </li>
              </ol>
            </div>
          </div>

          <div className="Homepage__card">
            <div className="d-flex">
              <div className="Homepage__number mobile__circle mt-5">2</div>
              <div className="Help_Homepage__card_title">
                Embed iFrame into Qualtrics
              </div>
            </div>
            <div className="Help_2_card2_description">
              <ol>
                <li>Log in to your Qualtrics account</li>
                <li>Click “Create a new project”</li>
                <li>
                  Select “Survey” under the heading “From scratch,” and click
                  “Get started”
                </li>
                <li>
                  Name your project, select an appropriate folder for it, and
                  under “How do you want to start your survey?” select “Import a
                  QSF file,” click “Choose file,” select the file{" "}
                  <a
                    href="https://rechat-public-assets.s3.us-east-2.amazonaws.com/ReChat_Template.qsf"
                    target="_blank"
                  >
                    “ReChat_Template.qsf”
                  </a>{" "}
                  and click “Create project.”
                </li>
                <li>
                  Your new survey will contain a minimal working example of a
                  ReChat study, including instructions (which suppose a 5-minute
                  waiting room, a 10-minute chat, and $1 compensation for
                  waiting-room time-outs - be sure to adjust these as
                  appropriate), as well as survey logic to handle waiting-room
                  time-outs and possible technical issues. Although technical
                  issues are rare (and typically the result of user error) it is
                  necessary to allow for the possibility that a participant
                  cannot obtain their completion code, since otherwise they will
                  be unable to complete the survey. Note that the “technical
                  issue” pathway opens the possibility for unscrupulous
                  participants to feign a technical issue to obtain payment
                  without completing a chat. In our studies, we do not believe
                  such behavior has occurred at significant rates, however it is
                  ultimately up to the researcher to decide how they wish to
                  handle these cases.
                </li>
                <li>
                  By default, the template file places users in a “Demo Chat”
                  that poses the question “Who do you think is more intelligent,
                  Democrats or Republicans?” Note that you will need to
                  “Preview” the survey to use the chat window; the chat window
                  will display a 404 error when viewed from the Qualtrics survey
                  editor. This is because the link to the chat template is
                  defined as an embedded data variable. To change from this Demo
                  Chat to the template you created in Part 1, enter the Survey
                  Flow editor (at time of writing, the button is located in the
                  lefthand sidebar of the Qualtrics survey editor), locate the
                  green “Set Embedded Data” block at the top of the default
                  survey flow, and change the value for “iframe_template_url”
                  from the default template URL to the URL that you copied for
                  the template you created in Part 1. Click “Apply” to save your
                  changes to the survey flow, and return to the main Qualtrics
                  survey building interface. When you click “Preview,” you will
                  be able to join a chat corresponding to the template you
                  created in Part 1. (Note that in order to experience a
                  2-person chat, you will need to open two windows - in addition
                  to the survey preview, you can open a new browser window and
                  paste the same Template URL that you just entered as embedded
                  data, and pretend to be a second person joining a chat.
                  Alternatively, ask a colleague to chat with you.)
                </li>
                <li>
                  Advanced users may wish to implement multiple chat templates
                  in a single survey. For example, a researcher might want to
                  randomize participants into one of two templates with
                  different settings, as part of an experiment. This can be
                  achieved by copying the “chat_iframe” question block in the
                  Qualtrics interface, and editing the underlying HTML that
                  defines the iframe. Qualtrics' interface is not optimized for
                  working with iframes, so in order to access the underlying
                  HTML, you will need to click just at the gray border of the
                  iframe in the survey editing interface (clicking within the
                  iframe will not work), then click the “Rich Content Editor”
                  tab at the top-left, and then click the “source” or “&lt;&gt;”
                  button (at time of writing, between the “U” underline button
                  and the “Less…” button). The user will then observe how the
                  embedded data field “iframe_template_url” has been passed in
                  as the “src” value for the iframe. Although the user can
                  hard-code a new template URL here, it is recommended (due to
                  the inconvenience of editing iframes in Qualtrics) to alter
                  the embedded data variable and set this variable equal to a
                  template URL in the Survey Flow editor, as described above.
                  After copying the chat_iframe and entering the additional
                  template URL, it will of course be necessary to implement a
                  treatment randomizer and a Display Logic that ensures that the
                  correct iframe is displayed to the correct treatment/control
                  group. If the researcher is not familiar with these tools, it
                  is recommended that they consult general purpose resources on
                  implementing randomizers and display logic in Qualtrics, as
                  this is beyond the scope of this tutorial.
                </li>
              </ol>
              <p>
                If you wish to proceed to Part 3, run a few chats to completion,
                and make sure all participants enter their completion codes into
                the survey. This will ensure that the chat and survey data can
                be linked for analysis.
              </p>
            </div>
          </div>

          <div className="Homepage__card">
            <div className="d-flex">
              <div className="Homepage__number mobile__circle mt-5">3</div>
              <div className="Help_Homepage__card_title">
                Download and Process Data
              </div>
            </div>
            <div className="Help_2_card3_description">
              <ol>
                <li>
                  To download chat data, locate your template in the “Room
                  Template List” and click the download (↓) button. Select the
                  date range for the chats you want to download and click
                  “Export”.
                </li>
                <li>
                  Download the survey data from Qualtrics in your preferred
                  format (we use CSV format).
                </li>
                <li>
                  The{" "}
                  <a
                    href="https://github.com/willschulz/rechat"
                    target="_blank"
                  >
                    “rechat” R package
                  </a>{" "}
                  provides functions to parse the exported chat data, featurize
                  the chat text, and conduct analyses that incorporate text
                  features as well as survey variables. The README.md file in
                  this package gives an overview of the functions and their
                  usage, and each function's documentation can be accessed by
                  prepending with ?, for example, ?featurizeChat will bring up
                  documentation for the featurizeChat function.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Help;
