import React from 'react';
import './pageNotFound.css';
import PageNotFoundSvg from './svg/404.svg'
import { HiOutlineEmojiSad } from "react-icons/hi";

const PageNotFound = () => {
    return (
        <div className="PageNotFound">
            <div className="PageNotFound_wrapper">
            <img className="PageNotFound_svg" src={PageNotFoundSvg} alt="404"/>
               <div className="PageNotFound_title">
               <HiOutlineEmojiSad className="sad_svg" />
               <h2>Page Not Found</h2>
               </div>
        <a href="https://reso.chat"><button className="PageNotFound_btn">Go To Home</button></a>
             </div>
        </div>
    )
}

export default PageNotFound;
