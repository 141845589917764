import './Signup.css';
import React, { useEffect, useState } from 'react';
import {Link, useHistory} from "react-router-dom";
import axios from '../../axios'

function Signup() {
    const history = useHistory();

    const [input ,setInput] =useState({
        firstName:"",
        lastName:"",
        organization:"",
        title:"",
        email:"",
        intendedUse:"",
        purpose:""
    })

    const [errors,setErrors]=useState({});
    const [message,setMessage]=useState('')

     
    const handleChange=(e)=>{
        setInput(
         {
             ...input,
             [e.target.name]:e.target.value
         }
        )
    }

    const validateInfo=()=>{
     let errors = {};

     if(!input.firstName){
     errors.firstName="First Name Required"
     }
     if(!input.lastName){
        errors.lastName="Last Name Required"
     }
    if(!input.organization){
        errors.organization="Organization Required"
    }
    if(!input.title){
        errors.title="Title Required"
    }
    if(!input.email){
        errors.email='Email Required';
      }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(input.email))
      {
        errors.email="Email adress is invalid!"
    }
    if(!input.intendedUse){
        errors.intendedUse="Intended Use Required"
    }
    if(!input.purpose){
        errors.purpose="Purpose Required"
    }
        return errors;
    }

    const handleSubmit = async(event)=> {
        event.preventDefault();
        
        setErrors(validateInfo(input));
        
        if(Object.keys(validateInfo(input)).length > 0){
            return;
        }
            else{
                try{
                    const res = await axios.post('/auth/signup',{
                        firstName:input.firstName,
                        lastName:input.lastName,
                        organization:input.organization,
                        title:input.title,
                        email:input.email,
                        intendedUse:input.intendedUse,
                        purpose:input.purpose
                        }) 
                        // console.log(res.data);
                        history.push('/thankyou') 
                   }
                   catch(e){
                    const message = e.response.data.error;
                      setMessage(message)  
                   }
            }
      }

    return (
        <div className="Signup">
            <div className="Homepage__bgbox1 hide_on_mobile" />
            <div className="Homepage__bgbox2 hide_on_mobile" />
            <div className="Signup__main" style={{marginBottom: "200px", marginTop: "100px"}}>
                <div className="Signup__left">
                    <div className="Signup__bigText">Sign-Up</div>
                    <div className="Signup__smallText">Already signed up?  <a href="https://admin.reso.chat/login" className='Signup__left_link'>Login Here</a></div>
                </div>
                <div className="Signup__right">
                    <form className="Signup__right_form">

                    <div className="form__input_wrapper">
                        <label className="form__input_label">First Name</label>
                        <input className="Signup__field" type="text" name="firstName"  value={input.firstName} onChange={handleChange} placeholder="Enter First Name"/>
                                  {errors.firstName && <p className="signup_error">{errors.firstName}</p>}
                     </div>       

                                  <div className="form__input_wrapper">
                        <label className="form__input_label">Last Name</label>
                        <input className="Signup__field" type="text" name="lastName"  value={input.lastName} onChange={handleChange} placeholder="Enter Last Name"/>
                                  {errors.lastName &&  <p className="signup_error">{errors.lastName}</p>}
                              </div>  

                                  <div className="form__input_wrapper">
                        <label className="form__input_label">Organization</label>
                        <input className="Signup__field" type="text" name="organization"  value={input.organization} onChange={handleChange} placeholder="Enter Organization"/>
                                  {errors.organization &&  <p className="signup_error">{errors.organization}</p>}
                                  </div>  

                                  <div className="form__input_wrapper">
                        <label className="form__input_label">Title</label>
                        <input className="Signup__field" type="text" name="title"  value={input.title} onChange={handleChange} placeholder="Enter Title"/>
                                  {errors.title &&  <p className="signup_error">{errors.title}</p>}
                                  </div>  

                                  <div className="form__input_wrapper">
                        <label className="form__input_label">Email</label>
                        <input className="Signup__field" type="email" name="email"  value={input.email} onChange={handleChange} placeholder="Enter Email"/>
                                  {errors.email && <p className="signup_error">{errors.email}</p>}
                                  </div>  

                                  <div className="form__input_wrapper">
                        <label className="form__input_label">Intended Use</label>
                        <input className="Signup__field" type="text"  name="intendedUse"  value={input.intendedUse} onChange={handleChange} placeholder="Enter Intended Use"/>
                                  {errors.intendedUse &&  <p className="signup_error">{errors.intendedUse}</p>}
                                  </div>  

                                  <div className="form__input_wrapper">
                        <label className="form__input_label">Purpose of Application</label>
                        <input className="Signup__field" type="text"  name="purpose"  value={input.purpose} onChange={handleChange} placeholder="Enter Purpose of Application"/>
                                  {errors.purpose &&  <p className="signup_error">{errors.purpose}</p>}
                            </div>  

                                  <p className="signup_backend_error">{message}</p>
                    </form>
                      
                   <button className="Signup__button" type="submit" onClick={handleSubmit}>Sign Up</button> 
                </div>
            </div>
        </div>

    )
}

export default Signup
