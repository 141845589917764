import React, { useEffect } from "react";
import "./App.css";
import Header from "./components/Header";
import Homepage from "./components/Homepage";
// import Login from './components/Login';
import Signup from "./components/Signup";
import Features from "./components/Features";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import About from "./components/About";
import Terms from "./components/Terms";
import Contact from "./components/Contactus";
import ThankYou from "./components/Thankyou";
import ScrollToTop from "./ScrollToTop";

import PageNotFound from "./components/PageNotFound";
import Help from "./components/Help";

function App() {
  // useEffect(()=>{
  //   window.scrollTo(0, 0);
  // })

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/" exact>
            <Header />
            <Homepage />
            <Footer />
          </Route>

          {/* <Route path="/login">
            <Header/>
            <Login/>
            <Footer/>
          </Route> */}

          <Route
            exact
            path="/login"
            render={() => (window.location = "https://admin.reso.chat/login")}
          />

          <Route path="/signup">
            <Header />
            <Signup />
            <Footer />
          </Route>

          <Route path="/features">
            <Header />
            <Features />
            <Footer />
          </Route>

          <Route path="/help">
            <Header />
            <Help />
            <Footer />
          </Route>

          <Route path="/about">
            <Header />
            <About />
            <Footer />
          </Route>

          <Route path="/terms">
            <Header />
            <Terms />
            <Footer />
          </Route>

          <Route path="/contact">
            <Header />
            <Contact />
            <Footer />
          </Route>

          <Route path="/thankyou">
            <Header /> <ThankYou /> <Footer />{" "}
          </Route>

          <Route exact component={PageNotFound} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
