import './Homepage.css';
import React from 'react';
import iPhoneImage from '../../assets/images/iPhones.png';
import BrowserImage from '../../assets/images/Browser.png';
import iPhoneWithImage from '../../assets/images/iPhoneWithImage.png';
// import IdeaIcon from '../../assets/images/Idea.png';
import iPhoneCrop from '../../assets/images/iPhoneCrop.png';
import {Link} from "react-router-dom";

function Homepage() {

    // useEffect(()=>{
    //     window.scrollTo(0, 0);
    // })

    return (
        <div className="Homepage">
            <div className="Homepage__bgbox1 hide_on_mobile" />
            <div className="Homepage__bgbox2 hide_on_mobile" />
            <div className="Homepage__top">
                <div className="Homepage__titleText">
                ReChat an integrated and interactive <br/>  chat tool that allows you to administer, <br/> monitor, and analyze live conversations.
                </div>
                
                {/* <div className="Homepage__smallText">
                    Start your free focus group today
                </div> */}
                
                <div className="Homepage__buttons">
                    <Link to="/signup" style={{textDecoration: "none"}}><button className="Homepage__button primary">Sign up</button></Link>
                    <a href="https://admin.reso.chat/login" style={{textDecoration: "none"}} ><button className="Homepage__button">Login</button></a>
                </div>

                <img src={iPhoneImage} className="Homepage__iphone" alt="iPhones"/>
                <img src={BrowserImage} className="Homepage__browser" alt="browser"/>
            </div>

            <div className="Homepage__features">
                <div className="Homepage__featureTitle">
                    Get your ReChat group now in just 10 minutes
                </div>
                <div className="Homepage__steps">
                    <div className="Homepage__card">
                        <div className="Homepage__number">1</div>
                        <div className="Homepage__card_title">Create your chat groups</div>
                        <div className="Homepage__card_description">Create chat rooms corresponding to your requirements.</div>
                    </div>
                    <div className="Homepage__card">
                        <div className="Homepage__number_2_4">2</div>
                        <div className="Homepage__card_title">Assign participants to  <br/> your chat groups</div>
                        <div className="Homepage__card_description">Assign your participants to designated chat rooms corresponding to your needs.</div>
                    </div>
                    <div className="Homepage__card">
                        <div className="Homepage__number">3</div>
                        <div className="Homepage__card_title">Moderate live conversations</div>
                        <div className="Homepage__card_description">Moderate your participants' live conversations by yourself or a bot you designated.</div>
                    </div>
                    <div className="Homepage__card">
                        <div className="Homepage__number_2_4">4</div>
                        <div className="Homepage__card_title">Analyse your participants <br/>  conversations</div>
                        <div className="Homepage__card_description">Export and analyze your participants text data from their conversation transcripts.</div>
                    </div>
                </div>

                {/* <Link to="/signup" style={{textDecoration:"none"}}>
                <button className="Homepage_Startnow_button primary" style={{marginBottom: "100px"}}>
                     Sign up & Login
                    <svg xmlns="http://www.w3.org/2000/svg" style={{marginLeft:"40px"}}width="24" fill="white" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg>
                </button>
                </Link> */}

                <div className="Homepage__buttons">
                    <Link to="/signup" style={{textDecoration: "none"}}><button className="Homepage__button primary">Sign up</button></Link>
                    <a href="https://admin.reso.chat/login" style={{textDecoration: "none"}}><button className="Homepage__button">Login</button></a>
                </div>
               
            </div>

            <div className="Homepage__display">
                <img src={iPhoneWithImage} className="Homepage__display_left hide_on_mobile" alt="iPhone"/>
                
                {/* <div className="Homepage__display_right">
                    <img src={IdeaIcon} alt="Idea"/>
                    <div className="Homepage__display_title">
                        easy, fast way to gather qualitative feedback online.
                    </div>
                    <div className="Homepage__display_description">
                         In order to make the best decisions possible, organizations should be using both quantitative (surveys) and qualitative (focus group) methods. 
                    </div>

                    <Link to="/features" style={{textDecoration:"none"}}>
                    <button className="Homepage__button primary" style={{marginBottom: "100px"}}>
                        Learn More
                    </button>
                    </Link>
                </div> */}
                
                <img src={iPhoneCrop} className="show_on_mobile" alt="iPhone Crop"/>
            </div>

        </div>
    )
}

export default Homepage
