import "./Header.css";
import React, { useEffect, useRef, useState } from "react";
// import UserIcon from '../../assets/images/User.png';
import { Link, NavLink } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { FaTimes } from "react-icons/fa";

function Header() {
  const [clicked, setClicked] = useState(false);

  let menuRef = useRef();
  useEffect(() => {
    let handler = (event) => {
      if (!menuRef.current.contains(event.target)) {
        setClicked(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  return (
    <div className="Header">
      <Link to="/" style={{ textDecoration: "none" }}>
        <div className="logo">ReChat</div>
      </Link>
      <div className="Header__right">
        <NavLink
          to="/"
          exact
          activeClassName="header_link_active"
          className="Header__link"
          style={{ textDecoration: "none" }}
        >
          <div>Home</div>
        </NavLink>
        <NavLink
          to="/about"
          exact
          activeClassName="header_link_active"
          className="Header__link"
          style={{ textDecoration: "none" }}
        >
          <div>About&nbsp;Us</div>
        </NavLink>
        <NavLink
          to="/features"
          exact
          activeClassName="header_link_active"
          className="Header__link"
          style={{ textDecoration: "none" }}
        >
          <div>Features</div>
        </NavLink>
        <NavLink
          to="/help"
          exact
          activeClassName="header_link_active"
          className="Header__link"
          style={{ textDecoration: "none" }}
        >
          <div>Help</div>
        </NavLink>
        <NavLink
          to="/terms"
          exact
          activeClassName="header_link_active"
          className="Header__link"
          style={{ textDecoration: "none" }}
        >
          <div>Terms&nbsp;&&nbsp;Privacy</div>
        </NavLink>
        <NavLink
          to="/contact"
          exact
          activeClassName="header_link_active"
          className="Header__link"
          style={{ textDecoration: "none" }}
        >
          <div>Contact&nbsp;Us</div>
        </NavLink>
      </div>

      <div className="menu_bars" onClick={() => setClicked(!clicked)}>
        {clicked ? (
          <FaTimes className="menu_icon" />
        ) : (
          <FiMenu className="menu_icon" />
        )}
      </div>

      <div ref={menuRef} className={clicked ? "nav_menu_active" : "nav_menu"}>
        <ul className="menu_ul">
          <li>
            <NavLink
              to="/"
              exact
              activeClassName="menu_link_active"
              className="menu_links"
              onClick={() => setClicked(false)}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              activeClassName="menu_link_active"
              className="menu_links"
              onClick={() => setClicked(false)}
            >
              About&nbsp;Us
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/features"
              activeClassName="menu_link_active"
              className="menu_links"
              onClick={() => setClicked(false)}
            >
              Features
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/help"
              activeClassName="menu_link_active"
              className="menu_links"
              onClick={() => setClicked(false)}
            >
              Help
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/terms"
              activeClassName="menu_link_active"
              className="menu_links"
              onClick={() => setClicked(false)}
            >
              Terms & Privacy
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              activeClassName="menu_link_active"
              className="menu_links"
              onClick={() => setClicked(false)}
            >
              Contact Us
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Header;
