import React from 'react';
import './thankyou.css';

function ThankYou() {
    return (
        <div className="thankyou">
            <div className="thankyou__bgbox1 "/>
            <div className="thankyou__bgbox2 "/>
            <div className="thankyou_wrapper">
                <div className="thankyou_title">
                    <h2>Thank You</h2>
                </div>
                <div className="thankyou_content">
                    <p>Thank you for Registering.</p>
                    <p>Once your request is approved, you will receive the login details</p>
                </div>
            </div>
            
        </div>
    )
}

export default ThankYou;
