import React from 'react';
import './About.css';

function About() {
    return (
        <div className="about">
            <div className="about__bgbox1 "/>
            <div className="about__bgbox2 "/>
            <div className="about_wrapper">
                <div className="about_title">
                    <h2>About Us</h2>
                </div>
                <div className="about_content">
                    <p>ReChat is a self-serve tool that allows you to design, administer, record, and analyze chats
                         among participants online. You can easily create chat sessions, assign partici-pants, embed your chat platform 
                         to any online surveys, monitor participants’ interac-tions live (either you or bots), and export chat data.</p>
                </div>
            </div>
            
        </div>
    )
}

export default About;
