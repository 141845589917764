import React, { useEffect, useState } from 'react';
import './contact_us.css';
import axios from '../../axios';

function Contact() {
    const [errors,setErrors]=useState({});
    const [randomNo,setRandomNo]=useState(0)

    const [input,setInput]= useState({
        userName:"",
        email:"",
        query:"",
        checkNo:""
    })

    useEffect(()=>{
        const min = 1;
        const max = 5000000;
        const rand =  min + (Math.random() * (max-min));
        setRandomNo(Math.round(rand))
    },[])

    // console.log(randomNo);

    const validateInfo=()=>{
        let errors = {};

        if(!input.email){
            errors.email=`Email Required !`
        } 
        if(!input.query){
            errors.query=`Message / Query Required !`
        }
        return errors;
     }

    const randomConvertNumber = Number(input?.checkNo)

    const handleChange=(e)=>{
        setInput(
         {
             ...input,
             [e.target.name]:e.target.value
         }
        )
    }

    const handleSubmit=async(e)=>{
       e.preventDefault();
       setErrors(validateInfo())
       if(Object.keys(validateInfo()).length > 0){
        return;
       }else{ 
      try{
       const res = await axios.post(`/guest/mailadmin`,{
           name: input.userName,
           email: input.email,
           subject:"Enquiry Mail",
           message:input.query,
        })
        console.log(res);
       if(res.status === 204 ){
         setInput({userName: "",email:"" ,checkNo:"",query:""}) 
         alert("Your message has been sent to the ReChat Administrator")
       }
      }catch(e){
          console.log(JSON.stringify(e));
      }
     }
    }
    console.log(input);

     
    return (
        <div className="contact">
             <div className="contact__bgbox1 "/>
             <div className="contact__bgbox2 "/>
            <div className="contact_wrapper">
                <div className="contact_title">
                    <h2>Contact Us</h2>
                </div>
                <div className="contact_content">
                   <form className="contact_form">
                       <div style={{display:'flex'}}>
                       <input  type="text" name="userName" value={input.userName} onChange={handleChange} placeholder="Name" className="contact_input"/>

                       <input type="email" name="email"  value={input.email} onChange={handleChange} placeholder="Email" className="contact_input"/>
                       </div>
                       {errors.email && <p className="contact_error">{errors.email}</p>}

                       <textarea className="contact_textarea" name="query"  value={input.query} onChange={handleChange}  placeholder="Message / Query"/>
                       {errors.query && <p className="contact_error" >{errors.query}</p>}

                                        <div className="randomNo" > {randomNo} 
                                        <span className="randomDisclaimer">To prove that you are not a bot please enter this no in the input field !</span>
                                        <input type="number" name="checkNo" value={input.checkNo} onChange={handleChange} placeholder="Enter Number" className="contact_input"/>
                                        </div>
                        
                     {
                         randomNo === randomConvertNumber ? (
                            <button className="contact_btn"  onClick={handleSubmit} >Submit Message</button>
                         ):(
                            <button className="contact_disabledBtn" disabled >Submit Message</button>
                         )
                     }

                   </form>
                </div>
            </div>
        </div>
    )
}

export default Contact;
